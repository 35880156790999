import React, { useContext } from 'react'
import { Box } from '@mui/system'
import { navigate } from 'gatsby'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
const LoadingPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    ></Box>
  )
}

function AccessCheck({ children, access }) {
  const { gLoginEmployee } = useContext(GlobalStateContext)
  if (gLoginEmployee.loginInfo.access.length > 0) {
    //依照權限，判斷可不可以看到頁面
 //   if (gLoginEmployee.loginInfo.access.includes(access)) {
    if (gLoginEmployee.loginInfo.access) {
      return children
    } else {
      navigate(`/403`)
      return <LoadingPage />
    }
  } else {
    return <LoadingPage />
  }
}
export default AccessCheck

import React, {useEffect, useState, useContext, useRef} from "react"
import Layout from "../../layouts"
import { Link } from "gatsby"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import MUIDataTable from "mui-datatables"
import {createTheme, ThemeProvider} from "@mui/material/styles"
import {
  Card,
  CardHeader,
  List,
  ListItem,
  Typography,
  CardContent,
  IconButton,
  Box,
  Button,
  TextField,
  FormLabel,
} from "@mui/material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import DeleteItem from "../../components/attendance/deleteItem"
import AccessCheck from "../../components/attendance/accessCheck"
import EmployeeRoleChip from "../../components/attendance/employeeRoleChip"
import Hider from "../../components/utils/Hider"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import {CfpDropdown, DownloadList} from "../../components/cfp/cfpDropdown";
import {gUtils} from "../../components/utils/gutils";
import {navigate} from "gatsby";
export default function EmployeesInfo({ location }) {
  const {  gCompanyEmployee, gCfpCards, gCfpfp ,gCfp} =
    useContext(GlobalStateContext)
  const [editEmployeeInfo, setEditEmployeeInfo] = useState({})
  const [employeeKeys, setEmployeeKeys] = useState([])
  const [edit, setEdit] = useState(false)
  const { state = {} } = location||{}

  const [openDropDown, setOpenDropDown] = useState(false)
  const [listDown, setlistDown] = useState([])
  const [data, setData] = useState({})
  const ref = useRef(null)
    const [dropdownPosition, setDropdownPosition] = useState({left: 0, top: 0})

    useEffect(() => {
        if (!state||!state.data || Object.keys(state.data).length === 0) {
            navigate("/");
        }else {
            setData(state.data)



            let  devices=gCfp.cfpDevices.map(item=>item.deviceUUID).join(",")
            console.log("gCfp.cfpDevices",devices)
            //   getEmployeeKeys(state.data.companyID,state.data.subUUID)
            gCfpfp.getMemberFingers(devices, state.data.subUUID)
            gCfpCards.getMemberCards(state.data.subUUID)
            gCompanyEmployee.getLock(state.data.companyID,state.data.subUUID,(res)=>{

                //   console.log("当前测试数据",res,gCfp.cfpSsmDevices)
                let nresult = res.map(item => {
                    const matchingDevice = gCfp.cfpSsmDevices.find(i => i.deviceUUID === item.deviceUUID);
                    let  data='常時利用'

                    if (item.endAt && item.startAt) {
                        item.startTime=item.startAt
                        item.endTime=item.endAt
                        data=gUtils.getStartTimeEndTime(item)
                    }
                    if (matchingDevice) {
                        return { ...item, deviceName: matchingDevice.deviceName,msgData:data };
                    } else {

                        return { ...item, deviceName: undefined ,msgData:data};
                    }

                }).filter(item => {
                    // Only keep items where a matching device was found
                    return item.deviceName !== undefined;
                });


                setEmployeeKeys(nresult)
            })
        }

  }, [state])

    useEffect(()=>{
        gCompanyEmployee.setIsOpenDialog(false)
    },[])

  useEffect(()=>{
    setEditEmployeeInfo({employeeName:data.employeeName,phone:data.phone,department:data.department})
  },[data])


  const title = {
    width: "180px",
    fontWeight: "bold",
  }

  const infoItem = {
    display: "flex",
    alignItems: "center",
  }

  const customTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none",
                    "&:first-of-type": {
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: "6px",
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#f8f8f8",
                    },
                    "&:hover": {
                        cursor: "pointer",
                    },
                },
            },
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    border: "none"
                },
            },
        },

    },
  })

  return (
    <Layout location={location}>
      <AccessCheck access="ユーザー">
        <Card sx={{ mb: "15px" }}>
          <Hider show={!edit}>
            <Box>
              <CardHeader
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton size="small" component={Link} to="/employees">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography variant="h2" sx={{ ml: "9px" }}>
                      {data.employeeName}
                    </Typography>
                    <IconButton
                      sx={{ ml: "2px" }}
                      size="small"
                      onClick={() => {
                        setEdit(true)
                      }}
                    >
                      <EditRoundedIcon
                        fontSize="inherit"
                        sx={{ color: "primary.main", fontSize: "14px" }}
                      />
                    </IconButton>
                  </Box>
                }
              />
              <CardContent>
                <List
                  disablePadding
                  sx={{
                    "> .css-1samsxy-MuiListItem-root": {
                      padding: "0px",
                    },
                  }}
                >
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>メールアドレス</Typography>
                    {data.employeeEmail}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>サブUUID</Typography>
                    {data.subUUID}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>ロール</Typography>
                    {data.tag &&
                      data.tag.map((tag, index) => {
                        return (
                          <EmployeeRoleChip
                            key={index}
                            handleCheck={() => {
                              /*       let newTag = [...editEmployeeInfo.tag]
                              newTag.splice(index, 1)
                              setEditEmployeeInfo({
                                ...editEmployeeInfo,
                                tag: newTag,
                              })*/
                            }}
                            label={tag}
                            rowChipLength={1}
                          />
                        )
                      })}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>所属</Typography>
                    {data.department === undefined ? "未設定" : data.department}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>電話番号</Typography>{" "}
                    {data.phone === undefined ? "未設定" : data.phone}
                  </ListItem>
                </List>
              </CardContent>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <IconButton
                size="small"
                sx={{ mt: "3px" }}
                component={Link}
                to="/employees"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Box>
                <Box sx={{ ...infoItem }}>
                  <FormLabel sx={{ ...title, color: "black" }} required>
                    ユーザー名
                  </FormLabel>
                  <TextField
                    sx={{ minWidth: "350px" }}
                    size="small"
                    required
                    value={editEmployeeInfo.employeeName}
                    onChange={(e) => {
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        employeeName: e.target.value.trim(),
                      })
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    ...infoItem,
                    mt: "10px",
                  }}
                >
                  <FormLabel sx={{ ...title, color: "black" }}>所属</FormLabel>
                  <TextField
                    size="small"
                    value={editEmployeeInfo.department}
                    sx={{ minWidth: "350px" }}
                    onChange={(e) => {
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        department: e.target.value.trim(),
                      })
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    ...infoItem,
                    mt: "10px",
                  }}
                >
                  <FormLabel sx={{ ...title, color: "black" }}>
                    電話番号
                  </FormLabel>
                  <TextField
                    sx={{ minWidth: "350px" }}
                    size="small"
                    value={editEmployeeInfo.phone}
                    onChange={(e) => {
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        phone: e.target.value.trim(),
                      })
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mt: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setEdit(false)
                      setEditEmployeeInfo({})
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button
                    disableElevation
                    size="small"
                    disabled={editEmployeeInfo.employeeName === ""}
                    variant="outlined"
                    onClick={() => {
                      setEdit(false)
                      let   n={...editEmployeeInfo,companyID:data.companyID,subUUID:data.subUUID,tag:data.tag}
                      gCompanyEmployee.changeMemberInfo(n,()=>{
                        setData(prevState => ({...prevState, ...n}));

                      })

                    }}
                  >
                    確定
                  </Button>
                </Box>
              </Box>
            </Box>
          </Hider>
        </Card>

        <Box sx={{margin: "0px 20px 40px"}}>
          <ThemeProvider theme={customTheme}>
            <MUIDataTable
              title={
                <Typography sx={{ fontSize: "22px", fontFamily: "'Noto Sans JP', sans-serif", fontWeight: "bold", marginLeft: "-10px"}}>
                  {/* {`${data.employeeName}合鍵一覧`} */}
                  合鍵一覧
                </Typography>
              }
              data={employeeKeys}
              columns={[
                { name: "deviceName", label: "デバイス名" },
                { name: "deviceUUID", label: "UUID" },
                { name: "deviceModel", label: "デバイス種類" },
                {
                  name: "keyLevel",
                  label: "合鍵権限",
                  options: {
                    customBodyRender: (value) => {

                      let  data='ゲスト鍵'

                      if (value==='0'||value===0){
                        data='オーナー鍵'
                      }
                      if (value==='1'||value===1){
                        data='マネージャー鍵'
                      }
                      return (
                        <>
                          {data}
                        </>
                      )
                    },
                  },
                },
                {
                  name: "msgData",
                  label: "有効時間",
                  options: {
                      customBodyRender: (value) => {
                          return (
                              <Typography sx={{fontSize: "14px"}}>{value}</Typography>
                          )


                    },
                  },
                },
                {
                  name: "削除",
                  options: {
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                      return (
                        <DeleteItem
                          handleCheck={(e) => {
                            gCompanyEmployee.delLock({
                              subUUID:employeeKeys[dataIndex].subUUID,
                              deviceUUID: employeeKeys[dataIndex].deviceUUID
                            },(id)=>{
                              setEmployeeKeys(prevState => prevState.filter(item=>item.deviceUUID!==id))
                            })
                          /* API.del("Attendance", `/employee/key`, {
                              body: {
                                subUUID: employeeInfo.subUUID,
                                deviceUUID: employeeKeys[dataIndex].deviceUUID,
                              },
                            })
                              .then(() => {
                                let tmp = [...employeeKeys]
                                tmp.splice(dataIndex, 1)
                                setEmployeeKeys(tmp)
                              })
                              .catch((error) => {
                                console.log(error.response)
                              })*/
                          }}
                        />
                      )
                    },
                  },
                },
              ]}
              options={{
                setTableProps: () => {
                  return {
                    fullWidth: true,
                    size: "small",
                  }
                },
                setRowProps: (row, dataIndex) => {
                  return {
                    sx: {
                      "> td > div": {
                        color:
                          employeeKeys[dataIndex].endAt <
                          Math.floor(new Date().getTime() / 1000)
                            ? "#cccccc"
                            : "black",
                      },
                    },
                  }
                },
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null,
                pagination: false,
                selectableRows: "none",
                elevation: 0,
                textLabels: {
                  body: {
                    noMatch: "検索結果は０件です",
                    toolTip: "ソート",
                    columnHeaderTooltip: (column) => `ソート ${column.label}`,
                  },
                },
              }}
            />
          </ThemeProvider>
        </Box>
        <Box sx={{margin: "0px 20px 40px"}}>
          {gCfpCards.mmCards.length > 0 && (
            <ThemeProvider theme={customTheme}>
              <MUIDataTable
                data={gCfpCards.mmCards}
                title={
                  <Typography sx={{ fontSize: "22px", fontFamily: "'Noto Sans JP', sans-serif", fontWeight: "bold", marginLeft: "-10px"}}>
                    {/* {`${data.employeeName}カード一覽`} */}
                    カード一覽
                  </Typography>
                }
                columns={[
                  {
                    name: "name",
                    label: "カード名",
                  },
                  {
                    name: "cardID",
                    label: "ID",
                  },
                  {
                    name: "uuids",
                    label: "認証機器",
                      options: {
                          customBodyRender: (value, tableMeta, updateValue) => {



                              let len = gUtils.uuidsToNames(value, gCfp.cfpDevices)


                              return (
                                  <>
                                      <div ref={ref}>
                                          <Typography
                                              sx={{fontSize: "14px !important"}}
                                              onClick={(e) => {
                                                  const rect = e.currentTarget.getBoundingClientRect()
                                                  setDropdownPosition({left: rect.left, top: rect.top})

                                                  setOpenDropDown(true)
                                                  setlistDown(value)
                                              }}
                                          >{`${len?.length ?? 0}台の認証機器`}</Typography>
                                      </div>
                                  </>
                              )
                          },
                      },
                  },
                ]}
                options={{
                  elevation: 0,
                  print: false,
                  download: false,
                  hover: false,
                  filter: false,
                  search: false,
                  viewColumns: false,
                  pagination: false,
                  selectableRows: false,
                  selectToolbarPlacement: "none",
                  setTableProps: () => {
                    return { fullWidth: true, size: "small" }
                  },


                }}
              />
            </ThemeProvider>
          )}
        </Box>

        <Box sx={{margin: "0px 20px 40px"}}>
          {gCfpfp.fMmList.length > 0 && (
            <ThemeProvider theme={customTheme}>
              <MUIDataTable
                data={gCfpfp.fMmList}
                title={
                  <Typography sx={{ fontSize: "22px", fontFamily: "'Noto Sans JP', sans-serif", fontWeight: "bold", marginLeft: "-10px"}}>
                    {/* {`${data.employeeName}指紋一覽`} */}
                    指紋一覽
                  </Typography>
                }
                columns={[
                  {
                    name: "name",
                    label: "指紋名",
                  },
                  {
                    name: "fingerID",
                    label: "ID",
                  },
                  {
                    name: "deviceID",
                    label: "認証機器",


                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                        return <Typography sx={{fontSize: "14px !important"}}>{gCfp.findDeviceUUIDName(value)}</Typography>
                      },
                    },
                  },
                ]}
                options={{
                  elevation: 0,
                  print: false,
                  download: false,
                  hover: false,
                  filter: false,
                  search: false,
                  viewColumns: false,
                  pagination: false,
                  selectableRows: false,
                  selectToolbarPlacement: "none",
                  setTableProps: () => {
                    return { fullWidth: true, size: "small" }
                  },
                }}
              />
            </ThemeProvider>
          )}
          {/* </Hider> */}
        </Box>
          <CfpDropdown
              position={dropdownPosition}
              open={openDropDown}
              closeOpen={() => {
                  setOpenDropDown(false)
              }}
              viewRef={ref}
          >


              <DownloadList
                  list={gUtils.uuidsToNames(listDown, gCfp.cfpDevices)}
              />
          </CfpDropdown>

      </AccessCheck>
    </Layout>
  )
}
